<script>
import { profileMethods } from "@/state/helpers";
export default {
	name: "securePaymentReturn",
	data() {
		return {

		}
	},
	computed: {
		fullUrl() {
			return this.$route.fullPath
		}
	},
	methods: {
		...profileMethods
	},
	mounted() {
		this.$store.dispatch('profile/securePaymentProvider', this.fullUrl)
	}
}
</script>

<template>
	<div>
		<div class="layout-wrapper d-lg-flex">
			<div class="w-100">
				<div class="m-auto col-md-8 col-lg-6 col-xl-5">
					<div class="card" style="margin-top: 120px;">
						<div class="card-body p-4">
							<div class="text-center px-4">
								<h2>Please, wait. Payment processing...</h2>
							</div>
							<div class="m-auto text-center pt-4 relative">
								<div class="snippet" data-title="dot-pulse">
									<div class="stage">
										<div class="dot-pulse"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>
.snippet {
	position: relative;
	padding: 32px 5%;
}
.stage {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	padding: 32px 0;
	margin: 0 -5%;
	overflow: hidden;
}
.dot-pulse {
	position: relative;
	left: -9999px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #9880ff;
	color: #9880ff;
	box-shadow: 9999px 0 0 -5px;
	animation: dot-pulse 1.5s infinite linear;
	animation-delay: 0.25s;
}
.dot-pulse::before, .dot-pulse::after {
	content: "";
	display: inline-block;
	position: absolute;
	top: 0;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #9880ff;
	color: #9880ff;
}
.dot-pulse::before {
	box-shadow: 9984px 0 0 -5px;
	animation: dot-pulse-before 1.5s infinite linear;
	animation-delay: 0s;
}
.dot-pulse::after {
	box-shadow: 10014px 0 0 -5px;
	animation: dot-pulse-after 1.5s infinite linear;
	animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
	0% {
		box-shadow: 9984px 0 0 -5px;
	}
	30% {
		box-shadow: 9984px 0 0 2px;
	}
	60%, 100% {
		box-shadow: 9984px 0 0 -5px;
	}
}
@keyframes dot-pulse {
	0% {
		box-shadow: 9999px 0 0 -5px;
	}
	30% {
		box-shadow: 9999px 0 0 2px;
	}
	60%, 100% {
		box-shadow: 9999px 0 0 -5px;
	}
}
@keyframes dot-pulse-after {
	0% {
		box-shadow: 10014px 0 0 -5px;
	}
	30% {
		box-shadow: 10014px 0 0 2px;
	}
	60%, 100% {
		box-shadow: 10014px 0 0 -5px;
	}
}
</style>